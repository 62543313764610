<template>
  <b-card-code
    no-body
    :title="title"
  >
    <b-table
      responsive
      :items="items"
      class="mb-0"
    />

  </b-card-code>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
// import { codeBasic } from './code'

export default {
  components: {
    BCardCode,
    BTable,
  },
  props: {
    items: {
      type: Array,
      
    },
    title: {
      type: String,
      default: 'Data Kambing',
    },
  },
  data() {
    return {
    }
  },
}
</script>